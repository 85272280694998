import { Col, Row } from 'antd'
import { Chart, FAQ } from '@myportal/ecedo-myportal-frontend-components'
import { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

import LayoutBase from '../components/LayoutBase'
import useLanguage from '../hooks/useLanguage'
import { localizedRoutes } from '../utils'

interface Charts {
  token: string
}

const Charts: FunctionComponent<Charts> = ({ token }) => {
  const { language } = useLanguage()
  const navigate = useNavigate()

  const handleRedirect = useCallback(() => {
    navigate('/')
  }, [navigate])

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.charts}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24}>
          <Chart
            baseUrl={window.brandConfig.baseUrl}
            labels={window.brandConfig.chartLabels}
            token={token}
            language={language}
            redirect={handleRedirect}
            showCost={window.brandConfig.showCostChart}
          />
        </Col>
        <Col xs={24}>
          <FAQ baseUrl={window.brandConfig.baseUrl} token={token} language={language} page="usage" showFaqBot={window.brandConfig.showFaqBot} />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default Charts
