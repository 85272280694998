import { ConfigProvider } from 'antd'
import en from 'antd/es/locale/default'
import nl from 'antd/es/locale/nl_NL'
import { DataLabel } from '@myportal/ecedo-myportal-frontend-components/components/myportal/Chart/interfaces/DataLabel'
import { FunctionComponent } from 'react'
import { HelmetProvider } from 'react-helmet-async'

import Router from './Router'
import useLanguage from './hooks/useLanguage'

declare global {
  interface Window {
    brandConfig: {
      language?: string
      baseUrl: string
      baseMyPortalBackendUrl: string
      baseSignUpUrl: string
      privacyPolicyUrl?: string
      termsOfServiceUrl?: string
      languageUrl?: string
      loginLogoUrl?: string
      logoutRedirectUrl?: string
      translations?: any
      showFaqBot?: boolean
      showTwikey?: boolean
      showCharts: boolean
      showCostChart: boolean
      showFinancialDayInputs?: boolean
      showOrganizationListSelect?: boolean
      showAddendum?: boolean
      canChangeEmail: boolean
      canAddMeterReadings: boolean
      canChangeIBAN: boolean
      useEventDateForMeterReadingEntry: boolean
      googleTagManagerId?: string
      googleAnalyticsId?: string
      cookieExpires?: number
      showTerminationFeeCalculationTable?: boolean
      changePassword?: string
      chartLabels: {
        electricity?: DataLabel[]
        gas?: DataLabel[]
        cost?: DataLabel[]
        invoice?: DataLabel[]
        marketPriceElectricity?: DataLabel[]
        marketPriceGas?: DataLabel[]
      }
      awsConfig: {
        cognito: any
      }
    }
  }
}

const App: FunctionComponent = () => {
  const { language } = useLanguage()

  return (
    <HelmetProvider>
      <ConfigProvider locale={language === 'nl' ? nl : en}>
        <Router />
      </ConfigProvider>
    </HelmetProvider>
  )
}

export default App
