const nl = {
  general: 'Algemeen',
  connections: 'Mijn aansluitingen',
  financial: 'Facturen en betalen',
  myData: 'Mijn gegevens',
  contracts: 'Mijn overeenkomst',
  documents: 'Documenten',
  myMeterReadings: 'Mijn meterstanden',
  meterReadings: 'Meterstanden',
  logout: 'Uitloggen',
  faq: 'Vraag en antwoord',
  myLocations: 'Mijn locaties',
  consumption: 'Mijn verbruik',
  pageNotFound: 'Pagina niet gevonden.',
  goBackTo: 'Ga terug naar',
  dashboard: 'het dashboard',
  login: 'de inlogpagina',
  admin: 'Admin pagina',
  previousPage: 'Vorige pagina',
  contractRegistration: 'Klantnummer toevoegen',
  terminationFeeCalculation: 'Berekening opzegvergoeding',
  addendum: 'Verzoek om gegevensmutatie',
}

export type nlType = typeof nl

export default nl
