import { Col, Row } from 'antd'
import { FAQ, MyLocations } from '@myportal/ecedo-myportal-frontend-components'
import { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import LayoutBase from '../components/LayoutBase'
import useLanguage from '../hooks/useLanguage'
import { setDaughterOrganization } from '../store/reducers/organizationReducer'
import { getLocalizedRoutesForHistoryPush, localizedRoutes } from '../utils'

interface MyLocationsProps {
  token: string
}

const MyLocationsPage: FunctionComponent<MyLocationsProps> = ({ token }) => {
  const { language } = useLanguage()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSelect = useCallback(
    (value: { organizationReference: string }) => {
      dispatch(setDaughterOrganization(value))
      navigate(`${getLocalizedRoutesForHistoryPush('general')}/${value.organizationReference}`)
    },
    [dispatch, navigate]
  )

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.myLocations}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24}>
          <MyLocations baseUrl={window.brandConfig.baseUrl} token={token} language={language} selectCallback={handleSelect} />
        </Col>
        <Col xs={24}>
          <FAQ baseUrl={window.brandConfig.baseUrl} token={token} language={language} page="myLocations" showFaqBot={window.brandConfig.showFaqBot} />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default MyLocationsPage
